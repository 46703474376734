function CHSI_ErrorResult(e)
{ alert('An error occurred - ' + e.get_message()); }

function jQuery_ErrorResult(result) {
    if (result.status == 0)
    { }
    else
    {
        alert(result.status + ' ' + result.statusText);
    }


}
function FormatNumber(num, decimalNum, bolLeadingZero, bolParens, bolCommas, bolPadDecimals)
/**********************************************************************
IN:
NUM - the number to format
decimalNum - the number of decimal places to format the number to
bolLeadingZero - true / false - display a leading zero for
numbers between -1 and 1
bolParens - true / false - use parenthesis around negative numbers
bolCommas - put commas as number separators.
 
RETVAL:
The formatted number!
**********************************************************************/
{
    if (isNaN(parseInt(num))) return "NaN";

    var tmpNum = num;
    var iSign = num < 0 ? -1 : 1; 	// Get sign of number

    // Adjust number so only the specified number of numbers after
    // the decimal point are shown.
    tmpNum *= Math.pow(10, decimalNum);

    tmpNum = Math.round(Math.abs(tmpNum))
    tmpNum /= Math.pow(10, decimalNum);
    tmpNum *= iSign; 				// Readjust for sign

    // Create a string object to do our formatting on
    var tmpNumStr = new String(tmpNum);

    // See if we need to strip out the leading zero or not.
    if (!bolLeadingZero && num < 1 && num > -1 && num != 0)
        if (num > 0)
            tmpNumStr = tmpNumStr.substring(1, tmpNumStr.length);
        else
            tmpNumStr = "-" + tmpNumStr.substring(2, tmpNumStr.length);

    // See if we need to put in the commas
    if (bolCommas && (num >= 1000 || num <= -1000)) {
        var iStart = tmpNumStr.indexOf(".");
        if (iStart < 0)
            iStart = tmpNumStr.length;

        iStart -= 3;
        while (iStart >= 1) {
            if (iStart == 1 && tmpNumStr.charAt(0) == "-") {
                //We do not place a comma here, would be like saying -,1,000
                //This fixes continues even if we use the parans option theoretically it'd 
                //transform into (,1,000), but this conditional stops it.
            }
            else {
                tmpNumStr = tmpNumStr.substring(0, iStart) + "," + tmpNumStr.substring(iStart, tmpNumStr.length)
            }
            iStart -= 3;
        }
    }

    //handle partial decimals
    var decimalpos = tmpNumStr.indexOf(".") + 1;
    if (bolPadDecimals) {
        if (decimalpos == 0) {
            tmpNumStr = tmpNumStr + "."
            decimalpos = tmpNumStr.length;
            //For now, I'm ok with NOT forcing decimalNum positions, I just
            //want to avoid *partial* decimalNum, so we don't do anything
        }
        if (decimalpos + decimalNum != tmpNumStr.length) {
            while (decimalpos + decimalNum != tmpNumStr.length) {
                tmpNumStr = tmpNumStr + "0"
            }
        }
    }

    // See if we need to use parenthesis
    if (bolParens && num < 0)
        tmpNumStr = "(" + tmpNumStr.substring(1, tmpNumStr.length) + ")";
    //tmpNumStr = Left(tmpNumStr + '00', tmpNumStr.length);
    if (decimalNum == 0) {
        tmpNumStr = tmpNumStr.replace('.', '');
    }
    return tmpNumStr; 	// Return our formatted string!
}

function isnull(value, alternatevalue) {
    if (value == null) {
        return alternatevalue;
    }
    if (value == '') {
        return alternatevalue;
    }
};