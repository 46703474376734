/**
 * COMMON DHTML FUNCTIONS
 * 
 *Please load after jQuery but before Mocha
 * Richard Mathis
 *
 * Up to date code can be found at http://www.subimage.com/dhtml/
 *
 * This code is free for you to use anywhere, just keep this comment block.
 */

 
String.prototype.endsWith = function (str) {
    return (this.match(str + '$') == str)
}

jQuery.extend(
			jQuery.expr[":"],
			{
			    asp: "jQuery(a).attr('id').endsWith(m[3]);"
			}
		);

/**
 * X-browser event handler attachment and detachment
 * TH: Switched first true to false per http://www.onlinetools.org/articles/unobtrusivejavascript/chapter4.html
 *
 * @argument obj - the object to attach event to
 * @argument evType - name of the event - DONT ADD "on", pass only "mouseover", etc
 * @argument fn - function to call
 */
function addEvent(obj, evType, fn){
 if (obj.addEventListener){
	obj.addEventListener(evType, fn, false);
	return true;
 } else if (obj.attachEvent){
	var r = obj.attachEvent("on"+evType, fn);
	return r;
 } else {
	return false;
 }
}
function removeEvent(obj, evType, fn, useCapture){
  if (obj.removeEventListener){
	obj.removeEventListener(evType, fn, useCapture);
	return true;
  } else if (obj.detachEvent){
	var r = obj.detachEvent("on"+evType, fn);
	return r;
  } else {
	alert("Handler could not be removed");
  }
}

/**
 * Code below taken from - http://www.evolt.org/article/document_body_doctype_switching_and_more/17/30655/
 *
 * Modified 4/22/04 to work with Opera/Moz (by webmaster at subimage dot com)
 *
 * Gets the full width/height because it's different for most browsers.
 */
function getViewportHeight() {
	if (window.innerHeight!=window.undefined) return window.innerHeight;
	if (document.compatMode=='CSS1Compat') return document.documentElement.clientHeight;
	if (document.body) return document.body.clientHeight; 

	return window.undefined; 
}
function getViewportWidth() {
	var offset = 17;
	var width = null;
	if (window.innerWidth!=window.undefined) return window.innerWidth; 
	if (document.compatMode=='CSS1Compat') return document.documentElement.clientWidth; 
	if (document.body) return document.body.clientWidth; 
}

/**
 * Gets the real scroll top
 */
function getScrollTop() {
	if (self.pageYOffset) // all except Explorer
	{
		return self.pageYOffset;
	}
	else if (document.documentElement && document.documentElement.scrollTop)
		// Explorer 6 Strict
	{
		return document.documentElement.scrollTop;
	}
	else if (document.body) // all other Explorers
	{
		return document.body.scrollTop;
	}
}
function getScrollLeft() {
	if (self.pageXOffset) // all except Explorer
	{
		return self.pageXOffset;
	}
	else if (document.documentElement && document.documentElement.scrollLeft)
		// Explorer 6 Strict
	{
		return document.documentElement.scrollLeft;
	}
	else if (document.body) // all other Explorers
	{
		return document.body.scrollLeft;
	}
}

var PunctuationExp = /[$\\@\\\#%\^\*\(\)\[\]\+\_\{\}\`\~\=\|\.\!\<\?\/\,\>]/;
var PunctuationExpStrict = /[$\\@\\\#%\^\*\(\)\[\]\+\_\{\}\`\~\=\|\.\!\<\?\/\,\>]/;
 var wordsearch = / suite | ste /i;
function CheckBlank(val)
{
if (val.value == '')
{
val.value = 'Closed';
}
}

function SelectAll(val)
{
	val.select();
}
function dodacheck(val) {

 if (val.value.match(wordsearch))
 {
  val.value = val.value.replace(/ suite/i,'').replace(/ ste/i,'');
 }
  
}
function checkWithError(val, Msg) {
    if (val.value.match(wordsearch)) {
        val.value = val.value.replace(/ suite/i, '').replace(/ ste/i, '');
        alert(Msg);
    }
}

function isPlainText(e){
var keynum;
var keychar;
var numcheck;
if (window.event) //IE
{
keynum = e.keyCode;
}
else if(e.which) //Netscape/Firefox/Opera
{
keynum = e.which;
}
keychar = String.fromCharCode(keynum);
return !PunctuationExp.test(keychar);
}
function isNumberKey(evt){
	var charCode = (evt.which) ? evt.which : event.keyCode
		 if (charCode > 31 && (charCode < 48 || charCode > 57))
			return false;
		 return true;
	 }
 function isAlphaNumeric(evt) {
	 var charCode = (evt.which) ? evt.which : event.keyCode
	 if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		 //Not a number
		 if (charCode < 65 || charCode > 90) {
			 //not an upper case letter
			 if (charCode < 97 || charCode > 122) {
				 return false;
			 }
		 }
	 }
	 return true;
 }
 function isAddressKey(evt) {
	 if (isAlphaNumeric(evt)) {
		//We're definitely fine
	 }
	 else {
		 var charCode = (evt.which) ? evt.which : event.keyCode
		 if (charCode != 32 && charCode != 35 && charCode != 47 && charCode != 39) {  //Both 32 (space) and 35 (pound) are allowable.  Also, as of 8/6/10, allowing 47 (forward slash) per Colin. -Nick
         //As of 6/7/11, allowing 39 (apostrophe) -Jason
			return false
		 }
	 }
}
function isCityKey(evt) {
	if (isAlphaNumeric(evt)) {
		//We're definitely fine
	}
	else {
		var charCode = (evt.which) ? evt.which : event.keyCode
		if (charCode != 32) {  //32 (space) is allowable.
			return false
		}
	}
}
function isSSN(evt)
{
	var charCode = (evt.which) ? evt.which : event.keyCode
	if (charCode > 31 && (charCode < 48 || charCode > 57)){
		//Not a number
		if(charCode != 32 && charCode != 45){
			//not a - nor a space   
			return false;
		}
	}
	return true;
}
function isDoubleKey(evt)
{
	var charCode = (evt.which) ? evt.which : event.keyCode
 
	if (charCode > 31 && (charCode < 48 || charCode > 57)){
		//Not a number
		if(charCode != 46 && charCode != 45){
			//not a .
			return false;
		}
	}
	return true;
}
function isUSZip(evt)
{
	var charCode = (evt.which) ? evt.which : event.keyCode
	if (charCode > 31 && (charCode < 48 || charCode > 57)){
		//Not a number
		if(charCode != 45){
			//not a -
			return false;
		}
	}
	return true;
}
function isDate(evt)
{
	var charCode = (evt.which) ? evt.which : event.keyCode
	if (charCode > 31 && (charCode < 48 || charCode > 57)){
		//Not a number
		if(charCode != 47){
			//not a /
			return false;
		}
	}
	return true;
}
 
function isDateKey(evt){
var charCode = (evt.which) ? evt.which : event.keyCode
 
		 if ( charCode > 31 && (charCode < 48 || charCode > 57 )&& charCode!=47   )
			return false;
		 return true;
} 

//----------------- Date Functioins ---------------------------------------------------
		function addDays(myDate, days)
		{
		    return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
		}

//		function addHours(myDate, hours) {
//		    return new Date(myDate.getTime() + Hours * 60 * 60 * 1000);
//		}

//		function addMinutes(myDate, minutes) {
//		    return new Date(myDate.getTime() + minutes * 60 * 1000);
//		}

//		function addSeconds(myDate, seconds) {
//		    return new Date(myDate.getDate() + seconds * 1000);
//		}

//		function addMilliseconds(myDate, milliseconds) {
//		    return new Date(myDate.getDate() + milliseconds);
//		}

//-------------------------------------------------------------------------------------
		
		function gaussianRound(x) {    var absolute = Math.abs(x);    var sign     = x == 0 ? 0 : (x < 0 ? -1 : 1);    var floored  = Math.floor(absolute);    if (absolute - floored != 0.5) {        return Math.round(absolute) * sign;    }    if (floored % 2 == 1) {        return Math.ceil(absolute) * sign;    }    return floored * sign;}
		function gaussianRound2(x) {return gaussianRound(x*100)/100;}
		function isDate(dateStr) {return (!isNaN (new Date (dateStr).getYear () ) ) ;}
		function DateDiff(firstdate, seconddate) {var date1 = new Date(firstdate); var date2 = new Date(seconddate);var one_day=1000*60*60*24;return gaussianRound((date1-date2)/(one_day)) *-1}
function addCommas(nStr){	nStr += '';	x = nStr.split('.');	x1 = x[0];	x2 = x.length > 1 ? '.' + x[1] : '';	var rgx = /(\d+)(\d{3})/;	while (rgx.test(x1)) {		x1 = x1.replace(rgx, '$1' + ',' + '$2');	}	if (isNaN(nStr) == true){return '';} else { return x1 + x2;}}
function ForceZero(nStr){
	if(nStr == ""){
		nStr = 0;
	}
	return nStr;
}


function Right(str, n)
/***
IN: str - the string we are RIGHTing
n - the number of characters we want to return

RETVAL: n characters from the right side of the string
***/
{
	if (n <= 0)     // Invalid bound, return blank string
		return "";
	else if (n > String(str).length)   // Invalid bound, return
		return str;                     // entire string
	else { // Valid bound, return appropriate substring
		var iLen = String(str).length;
		return String(str).substring(iLen, iLen - n);
	}
}

function InStr(strSearch, charSearchFor)
/*
InStr(strSearch, charSearchFor) : Returns the first location a substring (SearchForStr)
was found in the string str.  (If the character is not
found, -1 is returned.)
						   
Requires use of:
Mid function
Len function
*/
{
	for (i = 0; i < Len(strSearch); i++) {
		if (charSearchFor == Mid(strSearch, i, 1)) {
			return i;
		}
	}
	return -1;
}
 
function FormatNumber(num,decimalNum,bolLeadingZero,bolParens,bolCommas,bolPadDecimals)
/**********************************************************************
	IN:
		NUM - the number to format
		decimalNum - the number of decimal places to format the number to
		bolLeadingZero - true / false - display a leading zero for
										numbers between -1 and 1
		bolParens - true / false - use parenthesis around negative numbers
		bolCommas - put commas as number separators.
 
	RETVAL:
		The formatted number!
 **********************************************************************/
{ 
		if (isNaN(parseInt(num ))) return "NaN";

	var tmpNum = num;
	var iSign = num < 0 ? -1 : 1;		// Get sign of number
	
	// Adjust number so only the specified number of numbers after
	// the decimal point are shown.
	tmpNum *= Math.pow(10,decimalNum);
 
	tmpNum = Math.round(Math.abs(tmpNum))
	tmpNum /= Math.pow(10,decimalNum);
	tmpNum *= iSign;					// Readjust for sign
	
	// Create a string object to do our formatting on
	var tmpNumStr = new String(tmpNum);
	
	// See if we need to strip out the leading zero or not.
	if (!bolLeadingZero && num < 1 && num > -1 && num != 0)
		if (num > 0)
			tmpNumStr = tmpNumStr.substring(1,tmpNumStr.length);
		else
			tmpNumStr = "-" + tmpNumStr.substring(2,tmpNumStr.length);
		
	// See if we need to put in the commas
	if (bolCommas && (num >= 1000 || num <= -1000)) {
		var iStart = tmpNumStr.indexOf(".");
		if (iStart < 0)
			iStart = tmpNumStr.length;

		iStart -= 3;
		while (iStart >= 1) {
			if(iStart == 1 && tmpNumStr.charAt(0) == "-"){
				//We do not place a comma here, would be like saying -,1,000
				//This fixes continues even if we use the parans option theoretically it'd 
				//transform into (,1,000), but this conditional stops it.
			}
			else{
				tmpNumStr = tmpNumStr.substring(0,iStart) + "," + tmpNumStr.substring(iStart,tmpNumStr.length)
			}
			iStart -= 3;
		}		
	}

	//handle partial decimals
	var decimalpos = tmpNumStr.indexOf(".") + 1;
	if(bolPadDecimals){
		if (decimalpos == 0){
			tmpNumStr = tmpNumStr + "."
			decimalpos = tmpNumStr.length;
			//For now, I'm ok with NOT forcing decimalNum positions, I just
			//want to avoid *partial* decimalNum, so we don't do anything
		}
		if(decimalpos + decimalNum != tmpNumStr.length){
			while (decimalpos + decimalNum != tmpNumStr.length){
				tmpNumStr = tmpNumStr + "0"
			}
		}
	}

	// See if we need to use parenthesis
	if (bolParens && num < 0)
		tmpNumStr = "(" + tmpNumStr.substring(1,tmpNumStr.length) + ")";
//tmpNumStr = Left(tmpNumStr + '00', tmpNumStr.length);
if (decimalNum ==0)
		{
		tmpNumStr=    tmpNumStr.replace('.', '');
		}
	return tmpNumStr;		// Return our formatted string!
}
function Automove(currentlength, maxlength, boxtomove){
	if(currentlength == maxlength){
		document.getElementById(boxtomove).focus();
	}
}

function SafeAdd(val1, val2, val3, val4, val5, val6, val7, val8) {
	var result = val1;
   
	if (val2 != undefined) {
		result = ((result * 10000) + (val2 * 10000)) / 10000;
	}
	if (val3 != undefined) {
		result = ((result * 10000) + (val3 * 10000)) / 10000;
	}
	if (val4 != undefined) {
		result = ((result * 10000) + (val4 * 10000)) / 10000;
	}
	if (val5 != undefined) {
		result = ((result * 10000) + (val5 * 10000)) / 10000;
	}
	if (val6 != undefined) {
		result = ((result * 10000) + (val6 * 10000)) / 10000;
	}
	if (val7 != undefined) {
		result = ((result * 10000) + (val7 * 10000)) / 10000;
	}
	if (val8 != undefined) {
		result = ((result * 10000) + (val8 * 10000)) / 10000;
	}
	return result;
}

function SafeSubtract(val1, val2, val3, val4, val5, val6, val7, val8) {
	var result = val1;
  
	if (val2 != undefined) {
		result = ((result * 10000) - (val2 * 10000)) / 10000;
	}
	if (val3 != undefined) {
		result = ((result * 10000) - (val3 * 10000)) / 10000;
	}
	if (val4 != undefined) {
		result = ((result * 10000) - (val4 * 10000)) / 10000;
	}
	if (val5 != undefined) {
		result = ((result * 10000) - (val5 * 10000)) / 10000;
	}
	if (val6 != undefined) {
		result = ((result * 10000) - (val6 * 10000)) / 10000;
	}
	if (val7 != undefined) {
		result = ((result * 10000) - (val7 * 10000)) / 10000;
	}
	if (val8 != undefined) {
		result = ((result * 10000) - (val8 * 10000)) / 10000;
	}
	return result;
}

function SafeMultiply(val1, val2, val3, val4, val5, val6, val7, val8) {
	var result = val1;
	if (val2 != undefined) {
		result = ((result * 10000) * (val2 * 10000)) / 100000000;
  
	}
	if (val3 != undefined) {
		result = ((result * 10000) * (val3 * 10000)) / 100000000;
	}
	if (val4 != undefined) {
		result = ((result * 10000) * (val4 * 10000)) / 100000000;
	}
	if (val5 != undefined) {
		result = ((result * 10000) * (val5 * 10000)) / 100000000;
	}
	if (val6 != undefined) {
		result = ((result * 10000) * (val6 * 10000)) / 100000000;
	}
	if (val7 != undefined) {
		result = ((result * 10000) * (val7 * 10000)) / 100000000;
	}
	if (val8 != undefined) {
		result = ((result * 10000) * (val8 * 10000)) / 100000000;
	}
	return result;
}

function SafeDivide(val1, val2, val3, val4, val5, val6, val7, val8) {
	var result = val1;
  
	if (val2 != undefined) {
		result = (result *10000) / (val2 * 10000);
	}
	if (val3 != undefined) {
		result = (result * 10000) / (val3 * 10000);
	}
	if (val4 != undefined) {
		result = (result * 10000) / (val4 * 10000);
	}
	if (val5 != undefined) {
		result = (result * 10000) / (val5 * 10000);
	}
	if (val6 != undefined) {
		result = (result * 10000) / (val6 * 10000);
	}
	if (val7 != undefined) {
		result = (result * 10000) / (val7 * 10000);
	}
	if (val8 != undefined) {
		result = (result * 10000) / (val8 * 10000);
	}
	return result;
}



function CustomConfirm(query, yesText, noText, sender, yesfunction, nofunction) {
    if (nofunction == null) {
        nofunction = "HideCustomConfirm()";
    }
    var senderObj = jQuery(sender);
    var top = senderObj.offset().top;
    var left = senderObj.offset().left;
    var WindowObj = jQuery(window);

    var popupbody = '<div class="ConfirmBoxBackground" style="width: ' + WindowObj.width() + 'px; height: ' + WindowObj.height() + 'px;"><div class="ConfirmBox" style="top: ' + top + 'px; left: ' + left + 'px" >' + query + '<div><input type="button" value="' + yesText + '" onclick="' + yesfunction + '"/><input type="button" value="' + noText + '" onclick="' + nofunction + '"/></div></div></div>';
    jQuery('body').append(popupbody);

}
function HideCustomConfirm() {
    jQuery('.ConfirmBoxBackground').remove();
}

function jQuery_ErrorResult(result) {
    if (result.status == 0)
    { }
    else
    {
        alert(result.status + ' ' + result.statusText);
    }
    
}

function isnull(value, alternatevalue) {
    if (value == null) {
        return alternatevalue;
}
if (value == '') {
    return alternatevalue;
}
}

/* Get the querystring */
function PageQuery(q) {
    if (q.length > 1) this.q = q.substring(1, q.length);
    else this.q = null;
    this.keyValuePairs = new Array();
    if (q) {

        for (var i = 0; i < this.q.split("&").length; i++) {
            this.keyValuePairs[i] = this.q.split("&")[i];

        }
    }
    this.getKeyValuePairs = function () { return this.keyValuePairs; }
    this.getValue = function (s) {
        for (var j = 0; j < this.keyValuePairs.length; j++) {
            if (this.keyValuePairs[j].split("=")[0] == s)
                return this.keyValuePairs[j].split("=")[1];
        }
        return false;
    }
    this.getParameters = function () {
        var a = new Array(this.getLength());
        for (var j = 0; j < this.keyValuePairs.length; j++) {
            a[j] = this.keyValuePairs[j].split("=")[0];
        }
        return a;
    }
    this.getLength = function () { return this.keyValuePairs.length; }
}
function queryString(key) {
    var page = new PageQuery(window.location.search);
    return unescape(page.getValue(key));
}

/*Adds indexOf to Array for IE <9  */
 
if (!Array.prototype.indexOf)
{
  Array.prototype.indexOf = function(elt /*, from*/)
  {
    var len = this.length >>> 0;

    var from = Number(arguments[1]) || 0;
    from = (from < 0)
         ? Math.ceil(from)
         : Math.floor(from);
    if (from < 0)
      from += len;

    for (; from < len; from++)
    {
      if (from in this &&
          this[from] === elt)
        return from;
    }
    return -1;
  };
};